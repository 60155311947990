// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables / Shared Bits
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'switch-input';

// Auth styles

.border-key{
    border-left: 30px solid $primary;
}

.auth{
    border-radius: 0;
    background: rgb(245, 245, 245);
}


// Dashboard Styles

.sidebar{
    background: white;
    width: 350px;
    flex: 1 0 350px;
    max-width: 350px;
    border-right: 1px solid rgba(86, 33, 117, 0.25);
}

.list-group-item-flex{
    display:flex;
    align-items: center;

    .text-content{
        display:block;
        flex:1;
    }
    .btn-content{
        flex:none;
    }
}
